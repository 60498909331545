import gql from 'graphql-tag';

export default gql`
mutation updateProjectRankByPk($projectId: Int!, $orderIndex: Int!) {
  update_projects_by_pk(
    pk_columns: { project_id: $projectId }, 
    _set: { order_index: $orderIndex }) {
    project_id
  }
}
`;
