const dashboardTourMixin = {
  methods: {
    dashboardTour() {
      if (!this.$localStorage.tourOfDashboardFinished) {
        this.$nextTick(() => {
          const dashboardTour = this.$shepherd({
            useModalOverlay: true,
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              classes: 'vue-tour',
              scrollTo: true,
              popperOptions: {
                modifiers: [{
                  name: 'offset',
                  options: { offset: [0, 10] },
                }],
              },
            },
            scrollTo: { behavior: 'smooth', block: 'center' },
          });
          dashboardTour.on('cancel', this.setTheLocalStorageForDashboardTour);
          dashboardTour.on('complete', this.setTheLocalStorageForDashboardTour);
          dashboardTour.addStep({
            id: 'dashboard-step-1',
            title: this.$t('tour.dashboard.step1.title'),
            text: this.$t('tour.dashboard.step1.text'),
            buttons: [
              {
                text: this.$t('tour.skip'),
                classes: 'light-grey-button prev',
                action: dashboardTour.cancel,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: dashboardTour.next,
              },
            ],
          });
          dashboardTour.addStep({
            id: 'dashboard-step-2',
            title: this.$t('tour.dashboard.step2.title'),
            text: this.$t('tour.dashboard.step2.text'),
            attachTo: {
              element: '[data-dashboard-step="2"]',
              on: 'bottom',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: dashboardTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: dashboardTour.next,
              },
            ],
          });
          dashboardTour.addStep({
            id: 'dashboard-step-3',
            title: this.$t('tour.dashboard.step3.title'),
            text: this.$t('tour.dashboard.step3.text'),
            attachTo: {
              element: '[data-dashboard-step="3"]',
              on: 'right',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: dashboardTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: dashboardTour.next,
              },
            ],
          });
          dashboardTour.addStep({
            id: 'dashboard-step-4',
            title: this.$t('tour.dashboard.step4.title'),
            text: this.$t('tour.dashboard.step4.text'),
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: dashboardTour.back,
              },
              {
                text: this.$t('tour.finish'),
                classes: 'green-button',
                action: dashboardTour.complete,
              },
            ],
          });
          dashboardTour.start();
        });
      }
    },
    setTheLocalStorageForDashboardTour() {
      this.$localStorage.tourOfDashboardFinished = true;
    },
  },
};

export default dashboardTourMixin;
