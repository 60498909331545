import gql from 'graphql-tag';

export default gql`
query getAllItemsOfProjectById($id: Int!) {
  projects(where: {project_id: {_eq: $id}}) {
    name
    published
    searchable
    listed
    incompatible_item
    is_shared_template
    language_id
    item_path
    child_pages(order_by: {order_index: asc}) {
      page_id
      group_id
      item_path
      parent_folder_id
      parent_project_id
      order_index
      slug
      created_at
      updated_at
      name
      published
      latest_page_version_id
      latest_page_version {
        user {
          email
        }
      }
      latest_published_page_version_id
      thumbnails
      searchable
      listed
      incompatible_item
      pages_tags {
        tag {
          name
        }
      }
      keywords
    }
  }
}
`;
