import gql from 'graphql-tag';

export default gql`
query getPublishStatusOfPageVersionsByProjectId($projectId: Int!) {
  projects_by_pk(project_id: $projectId) {
    child_pages {
      published
      latest_page_version {
        page_version_id
        published
      }
    }
  }
}
`;
