import gql from 'graphql-tag';

export default gql`
mutation CreatePage($obj: pages_insert_input!) {
  insert_pages_one(object: $obj) {
    page_id
      group_id
      parent_folder_id
      parent_project_id
      name
      slug
  }
}
`;
