import gql from 'graphql-tag';

export default gql`
mutation duplicateItem($object: duplicateSharedTemplateInput!) {
  duplicateSharedTemplate(object: $object) {
    page_id
    project {
      item_path
    }
    project_id
  }
}
`;
