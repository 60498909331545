var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "div",
        { staticClass: "action-buttons d-flex justify-content-between py-5" },
        [
          _c("div", [
            _vm.currentItem.type === "project"
              ? _c(
                  "div",
                  [
                    _vm.groupDomain &&
                    _vm.$hasPrivileges(
                      _vm.$privileges("CAN_CREATE_PAGES"),
                      _vm.userObject.isSysAdmin,
                      _vm.currentItem.privilegesForItem
                    )
                      ? _c("modal-component-page", {
                          attrs: {
                            "item-position": _vm.pagePosition,
                            "child-slugs": _vm.slugsOfAllChildItems,
                            "parent-item": _vm.currentItem,
                            "group-id": _vm.groupId,
                            "group-slug": _vm.groupSlug,
                            "group-domain": _vm.groupDomain,
                            "group-path": _vm.groupPath,
                            email: _vm.userObject.email
                          }
                        })
                      : _vm._e(),
                    _vm.$hasPrivileges(
                      _vm.$privileges("CAN_CREATE_PAGES"),
                      _vm.userObject.isSysAdmin,
                      _vm.currentItem.privilegesForItem
                    )
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.modal-component-page",
                                modifiers: { "modal-component-page": true }
                              }
                            ],
                            staticClass:
                              "green-button-transparent mr-3 border-0",
                            attrs: { "data-dashboard-step": "3" }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "plus-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("webmag.page_add")) + " "
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _vm.currentItem &&
                    _vm.groupDomain &&
                    (_vm.$hasPrivileges(
                      _vm.$privileges("CAN_CREATE_PAGES"),
                      _vm.userObject.isSysAdmin,
                      _vm.currentItem.privilegesForItem
                    ) ||
                      _vm.$hasPrivileges(
                        _vm.$privileges("CAN_CREATE_PROJECTS"),
                        _vm.userObject.isSysAdmin,
                        _vm.currentItem.privilegesForItem
                      ))
                      ? _c("modal-choose-item-type", {
                          staticClass: "d-inline-block",
                          attrs: {
                            "parent-item": _vm.currentItem,
                            pagePosition: _vm.pagePosition,
                            folderProjectPosition: _vm.folderProjectPosition,
                            "child-slugs": _vm.slugsOfAllChildItems,
                            "group-id": _vm.groupId,
                            "group-slug": _vm.groupSlug,
                            "group-path": _vm.groupPath,
                            "group-domain": _vm.groupDomain,
                            "is-sys-admin": _vm.userObject.isSysAdmin,
                            email: _vm.userObject.email
                          },
                          on: { "insert-data": _vm.refreshItemsQuery }
                        })
                      : _vm._e(),
                    _vm.currentItem &&
                    _vm.groupDomain &&
                    _vm.$hasPrivileges(
                      _vm.$privileges("CAN_CREATE_FOLDERS"),
                      _vm.userObject.isSysAdmin,
                      _vm.currentItem.privilegesForItem
                    )
                      ? _c("modal-create-new-folder", {
                          staticClass: "d-inline-block",
                          attrs: {
                            "folder-project-position":
                              _vm.folderProjectPosition,
                            "child-slugs": _vm.slugsOfAllChildItems,
                            "group-id": _vm.groupId,
                            "group-slug": _vm.groupSlug,
                            "group-path": _vm.groupPath,
                            "group-domain": _vm.groupDomain,
                            "parent-id": _vm.currentItem.id,
                            email: _vm.userObject.email
                          },
                          on: { "insert-data": _vm.refreshItemsQuery }
                        })
                      : _vm._e()
                  ],
                  1
                )
          ]),
          _c(
            "div",
            { staticClass: "table-search d-flex" },
            [
              _c(
                "b-input-group",
                { staticClass: "search-group d-flex align-items-center mr-2" },
                [
                  _c("b-icon", {
                    attrs: { icon: "search", "aria-hidden": "true", scale: "1" }
                  }),
                  _c("b-form-input", {
                    attrs: {
                      id: "filter-input",
                      type: "search",
                      placeholder: _vm.$t("webmag.search")
                    },
                    on: { input: _vm.searchFieldTriggered },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2 dark-grey-buttons",
                  class: { active: !_vm.isTableView },
                  on: {
                    click: function($event) {
                      return _vm.changeDashboardView("cards")
                    }
                  }
                },
                [
                  _c("b-icon", {
                    staticClass: "top--1",
                    attrs: {
                      icon: "grid3x3-gap-fill",
                      "aria-hidden": "true",
                      "font-scale": "1.1",
                      scale: "1.1"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "dark-grey-buttons",
                  class: { active: _vm.isTableView },
                  on: {
                    click: function($event) {
                      return _vm.changeDashboardView("table")
                    }
                  }
                },
                [
                  _c("b-icon", {
                    staticClass: "top--1",
                    attrs: {
                      icon: "list-task",
                      "aria-hidden": "true",
                      "font-scale": "1.1",
                      scale: "1.1"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm.currentItem.type !== "project"
        ? _c(
            "div",
            { staticClass: "pb-2 d-flex flex-wrap" },
            [
              _vm.projectAndFolderItems.length > 0
                ? _c("b-table", {
                    directives: [
                      {
                        name: "sortable",
                        rawName: "v-sortable",
                        value: {
                          items: _vm.projectAndFolderItems,
                          table: "projectAndFolder"
                        },
                        expression:
                          "{ items: projectAndFolderItems, table: 'projectAndFolder' }"
                      }
                    ],
                    key: _vm.projectAndFolderTableKey,
                    ref: "projectAndFolderTable",
                    attrs: {
                      fields: _vm.fields,
                      items: _vm.projectAndFolderItems,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                      "show-empty": "",
                      "empty-filtered-html": _vm.$t("webmag.emptyFilteredText"),
                      borderless: "",
                      dark: "",
                      fixed: "",
                      "table-class": {
                        "table-view": _vm.isTableView,
                        "card-view": !_vm.isTableView
                      }
                    },
                    on: {
                      "head-clicked": _vm.sortedProjectAndFolderTable,
                      "sort-changed": _vm.sortProjectAndFolderChanged,
                      "row-clicked": _vm.navigateToItem
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.isTableView
                          ? {
                              key: "table-colgroup",
                              fn: function(scope) {
                                return _vm._l(scope.fields, function(field) {
                                  return _c("col", {
                                    key: field.key,
                                    style: { width: field.width }
                                  })
                                })
                              }
                            }
                          : null,
                        {
                          key: "cell(image)",
                          fn: function(data) {
                            return [
                              data.item.type === "folder"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "folder",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              data.item.type === "project" && !data.item.image
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "cell-background-image d-flex justify-content-center align-items-center"
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "files",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              data.item.type === "project" && data.item.image
                                ? _c("div", {
                                    staticClass: "cell-background-image",
                                    style: `background-image: ${data.item.image}`
                                  })
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "cell(order)",
                          fn: function(data) {
                            return [
                              _c("span", { attrs: { id: data.item.slug } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatOrder")(data.item.order)
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                          }
                        },
                        {
                          key: "cell(tags)",
                          fn: function(data) {
                            return [
                              data.item.tags
                                ? _c(
                                    "ul",
                                    {
                                      staticClass: "d-flex align-items-start",
                                      attrs: {
                                        id: `tag-row-${data.item.type}-${data.item.id}`
                                      }
                                    },
                                    _vm._l(data.item.tags, function(
                                      item,
                                      index
                                    ) {
                                      return _c("li", { key: "tag-" + index }, [
                                        _vm._v(" " + _vm._s(item) + " ")
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm.showTooltipOfTags(data.item.tags)
                                ? _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: `tag-row-${data.item.type}-${data.item.id}`
                                      }
                                    },
                                    [
                                      data.item.tags
                                        ? _c(
                                            "ul",
                                            { staticClass: "tags-tooltip" },
                                            _vm._l(data.item.tags, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "li",
                                                { key: "tag-" + index },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item) + " "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "cell(lastUpdated)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(data.item.lastUpdated)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(created)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(data.item.created)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(status)",
                          fn: function(data) {
                            return [
                              data.item.type !== "folder" &&
                              (!data.item.incompatibleItem ||
                                _vm.userObject.isSysAdmin)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-start"
                                    },
                                    [
                                      _c("publish-status-icons", {
                                        key: `publishIcon-${data.item.id}-${_vm.counter}`,
                                        attrs: {
                                          item: data.item,
                                          id: "dashboard"
                                        }
                                      }),
                                      _c("searchable-status-icons", {
                                        attrs: {
                                          item: data.item,
                                          id: "dashboard"
                                        }
                                      }),
                                      _c("status-icons", {
                                        attrs: {
                                          "has-access-to-settings":
                                            _vm.canAccessFolderOrProjectSettings,
                                          item: data.item,
                                          id: "dashboard"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("listed-status-icons", {
                                        attrs: {
                                          "has-access-to-settings":
                                            _vm.canAccessFolderOrProjectSettings,
                                          item: data.item,
                                          id: "dashboard"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        },
                        {
                          key: "cell(dot)",
                          fn: function(data) {
                            return [
                              data.item.incompatibleItem
                                ? _c(
                                    "div",
                                    { staticClass: "pt-2" },
                                    [
                                      _c("incompatible-icon", {
                                        attrs: {
                                          item: data.item,
                                          id: "dashboard"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isTableView &&
                              (data.item.incompatibleItem !== true ||
                                _vm.userObject.isSysAdmin)
                                ? _c("dot-dropdown", {
                                    key:
                                      _vm.rerenderProjectAndFolderDropdownKey,
                                    attrs: {
                                      item: data.item,
                                      "parent-item": _vm.currentItem,
                                      "group-slug": _vm.groupSlug,
                                      "is-group-user":
                                        _vm.userObject.isGroupUser,
                                      "is-sys-admin": _vm.userObject.isSysAdmin
                                    },
                                    on: { "item-action": _vm.itemAction }
                                  })
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "cell(cards)",
                          fn: function(data) {
                            return [
                              !_vm.isTableView
                                ? _c("dashboard-item", {
                                    attrs: {
                                      item: data.item,
                                      "parent-item": _vm.currentItem,
                                      "has-access-to-settings":
                                        _vm.canAccessFolderOrProjectSettings,
                                      "group-slug": _vm.groupSlug,
                                      "is-group-user":
                                        _vm.userObject.isGroupUser,
                                      "is-sys-admin": _vm.userObject.isSysAdmin
                                    },
                                    on: {
                                      "update-item": _vm.refreshItemsQuery,
                                      "item-action": _vm.itemAction
                                    }
                                  })
                                : _vm._e(),
                              data.item.incompatibleItem &&
                              !_vm.userObject.isSysAdmin
                                ? _c("modal-incompatible-item", {
                                    ref: "incompatibleModalComponent",
                                    attrs: {
                                      item: data.item,
                                      "group-slug": _vm.groupSlug,
                                      slug: data.item.slug
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap" },
        [
          _vm.pageItems.length > 0
            ? _c("b-table", {
                directives: [
                  {
                    name: "sortable",
                    rawName: "v-sortable",
                    value: { items: _vm.pageItems, table: "pages" },
                    expression: "{ items: pageItems, table: 'pages' }"
                  }
                ],
                key: _vm.pageTableKey,
                ref: "pageTable",
                attrs: {
                  fields: _vm.fields,
                  items: _vm.pageItems,
                  filter: _vm.filter,
                  "filter-included-fields": _vm.filterOn,
                  borderless: "",
                  "show-empty": "",
                  "empty-filtered-html": _vm.$t("webmag.emptyFilteredText"),
                  dark: "",
                  fixed: "",
                  "table-class": {
                    "table-view": _vm.isTableView,
                    "card-view": !_vm.isTableView
                  }
                },
                on: {
                  "head-clicked": _vm.sortedPageTable,
                  "sort-changed": _vm.sortPageChanged,
                  "row-clicked": _vm.navigateToItem
                },
                scopedSlots: _vm._u(
                  [
                    _vm.isTableView
                      ? {
                          key: "table-colgroup",
                          fn: function(scope) {
                            return _vm._l(scope.fields, function(field) {
                              return _c("col", {
                                key: field.key,
                                style: { width: field.width }
                              })
                            })
                          }
                        }
                      : null,
                    {
                      key: "cell(image)",
                      fn: function(data) {
                        return [
                          _c("div", {
                            staticClass: "cell-background-image",
                            style: {
                              backgroundImage: data.item.image,
                              backgroundPosition: data.item.focalpoint
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(order)",
                      fn: function(data) {
                        return [
                          _c("span", { attrs: { id: data.item.slug } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatOrder")(data.item.order)) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(name)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                      }
                    },
                    {
                      key: "cell(tags)",
                      fn: function(data) {
                        return [
                          data.item.tags
                            ? _c(
                                "ul",
                                { staticClass: "d-flex" },
                                _vm._l(data.item.tags, function(item, index) {
                                  return _c("li", { key: "tag-" + index }, [
                                    _vm._v(" " + _vm._s(item) + " ")
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(keywords)",
                      fn: function(data) {
                        return [
                          data.item.keywords
                            ? _c(
                                "ul",
                                { staticClass: "d-flex" },
                                _vm._l(data.item.keywords, function(
                                  item,
                                  index
                                ) {
                                  return _c("li", { key: "keyword-" + index }, [
                                    _vm._v(" " + _vm._s(item) + " ")
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(lastUpdated)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            {
                              attrs: {
                                id: `tooltip-updated-by-${data.item.slug}`
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(data.item.lastUpdated)
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          data.item.updatedBy
                            ? _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: `tooltip-updated-by-${data.item.slug}`
                                  }
                                },
                                [
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "webmag.dashboardItems.lastUpdatedBy"
                                        )
                                      ) +
                                        " " +
                                        _vm._s(data.item.updatedBy)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(created)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("dateFormat")(data.item.created)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("publish-status-icons", {
                                key: `publishIcon-${data.item.id}-${_vm.counter}`,
                                attrs: { item: data.item }
                              }),
                              _c("status-icons", {
                                attrs: {
                                  "has-access-to-settings":
                                    _vm.canAccessPageSettings,
                                  item: data.item,
                                  "parent-item-type": _vm.currentItem.type,
                                  id: "dashboard"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(dot)",
                      fn: function(data) {
                        return [
                          _vm.isTableView &&
                          (data.item.incompatibleItem !== true ||
                            _vm.userObject.isSysAdmin)
                            ? _c("dot-dropdown", {
                                key: _vm.rerenderPageTableDropdownKey,
                                attrs: {
                                  item: data.item,
                                  "parent-item": _vm.currentItem,
                                  "group-slug": _vm.groupSlug,
                                  "is-project-published":
                                    _vm.isProjectPublished,
                                  "is-group-user": _vm.userObject.isGroupUser,
                                  "is-sys-admin": _vm.userObject.isSysAdmin
                                },
                                on: { "item-action": _vm.itemAction }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(cards)",
                      fn: function(data) {
                        return [
                          !_vm.isTableView
                            ? _c("dashboard-item", {
                                attrs: {
                                  item: data.item,
                                  "parent-item": _vm.currentItem,
                                  "group-slug": _vm.groupSlug,
                                  "has-access-to-settings":
                                    _vm.canAccessPageSettings,
                                  "is-project-published":
                                    _vm.isProjectPublished,
                                  "is-group-user": _vm.userObject.isGroupUser,
                                  "is-sys-admin": _vm.userObject.isSysAdmin
                                },
                                on: {
                                  "update-item": _vm.refreshItemsQuery,
                                  "item-action": _vm.itemAction
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isPreviewWindowOpen &&
      _vm.iframeSourceForPreview &&
      _vm.currentItem.name
        ? _c("modal-preview-page", {
            attrs: {
              "iframe-src": _vm.iframeSourceForPreview,
              "preview-action-object": _vm.previewActionObject,
              itemName: _vm.currentItem.name,
              "group-domain": _vm.groupDomain,
              "item-path": _vm.currentItem.itemPath
            }
          })
        : _vm._e(),
      _vm.showPublishSuccessModal && _vm.groupDomain
        ? _c("modal-show-publish-success", {
            attrs: {
              slug: _vm.publishedItem.slug,
              "group-slug": _vm.groupSlug,
              "group-domain": _vm.groupDomain,
              "slug-path": _vm.publishedItem.slugPath,
              pageType: _vm.publishedItem.type
            },
            on: {
              "modal-confirmed": function($event) {
                _vm.showPublishSuccessModal = false
              }
            }
          })
        : _vm._e(),
      _vm.currentItem.type === "project" && _vm.showPublishModal
        ? _c("modal-publish-item", {
            attrs: { item: { slug: _vm.slugOfPage } },
            on: {
              "publish-item": _vm.publishItemFromHeaderCall,
              "publish-cancel": _vm.publishItemCancel
            }
          })
        : _vm._e(),
      _vm.showShowUrlModal &&
      _vm.groupDomain &&
      _vm.currentItem.type !== "folder"
        ? _c("modal-show-url", {
            attrs: {
              slug: _vm.slugOfPage,
              "group-domain": _vm.groupDomain,
              "slug-path": `${_vm.parentSlugPath}/${_vm.slugOfPage}`,
              pageType: _vm.currentItem.type,
              itemPath: _vm.currentItem.itemPath
            },
            on: { "close-modal": _vm.closeShowUrlModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }