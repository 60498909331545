import gql from 'graphql-tag';

export default gql`
mutation DeleteItem($obj: DeleteItemInput!) {
  deleteItem(object: $obj) {
    page_id
    project_id
    folder_id
  }
}
`;
