import gql from 'graphql-tag';

export default gql`
mutation(
  $isPublished: Boolean!,
  $hasChanged: Boolean!,
  $publishButtonIsPressed: Boolean!
) {
  setProjectHasToBePublished(
    isPublished: $isPublished
    hasChanged: $hasChanged
    publishButtonIsPressed: $publishButtonIsPressed
  ) @client
}
`;
