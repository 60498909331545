import gql from 'graphql-tag';

export default gql`
query getSharedTemplates($groupId: Int!) {
  getSharedTemplates(object: {group_id: $groupId}) {
    name
    domain
    keywords
    description
    group_slug
    item_path
    slug
    slug_path
    tags
    thumbnails
    type
    page_id
    project_id
  }
}
`;
