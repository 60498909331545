import gql from 'graphql-tag';

export default gql`
query getFolderSettingsById($id: Int!) {
  folders(where: {
    folder_id: {_eq: $id}
  }) {
    name
    listed
    parent_folder_id
    slug
    language_id
    corporate_identity
    custom_css
  }
}
`;
