import gql from 'graphql-tag';

export default gql`
mutation updatePageAfterDuplicate($pageId: Int!, $isSharedTemplate: Boolean!, $orderIndex: Int!, $published: Boolean!) {
  update_pages_by_pk(
    pk_columns: { page_id: $pageId },
    _set: {
      is_shared_template: $isSharedTemplate,
      order_index: $orderIndex,
      published: $published,
      }
    ) {
    page_id
  }
}
`;
