import gql from 'graphql-tag';

export default gql`
mutation updateFolderRankByPk($folderId: Int!, $orderIndex: Int!) {
  update_folders_by_pk(
    pk_columns: { folder_id: $folderId }, 
    _set: { order_index: $orderIndex }) {
    folder_id
  }
}
`;
