<template>
  <b-modal
    id="incompatible-item"
    ref="incompatibleItem"
    :no-close-on-backdrop="(modalForDetailPage)"
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.incompatibleItem.title') }}</h3>
    </template>
    <div class="p-5">
      <div class="text-center">
        <h3 class="mb-4">{{ item.name }}</h3>
        <h4 class="mb-4">
          <strong>{{ $t('modals.incompatibleItem.desc') }}</strong>
        </h4>
        <div v-if="item.published">
          <a :href="publishingUrl" target="_blank">{{ publishingUrl }}</a>
        </div>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <b-button
        v-if="modalForDetailPage"
        class="p-3 border-right-light-grey"
        block
        @click="redirectToDashboard"
      >
        {{ $t('buttons.toDashboard') }}
      </b-button>
      <b-button
        v-else
        class="p-3 border-right-light-grey"
        block
        @click="close()"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalIncompatibleItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    modalForDetailPage: {
      type: Boolean,
      default: false,
    },
    groupSlug: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    publishingUrl: null,
  }),
  created() {
    const groupPreviewDomain = process.env.VUE_APP_DOMAIN.replace('[group_slug]', this.groupSlug);
    this.publishingUrl = `${groupPreviewDomain}${this.slugPath}`;
    // if project add / at the end
    if (this.item.type === 'project') {
      this.publishingUrl = `${this.publishingUrl}/`;
    }
  },
  mounted() {
    if (this.modalForDetailPage) {
      this.$refs.incompatibleItem.show();
    }
  },
  computed: {
    slugPath() {
      return (this.$route.fullPath === '/')
        ? `/${this.item.slug}`
        : `${this.$route.fullPath}/${this.item.slug}`;
    },
  },
  methods: {
    redirectToDashboard() {
      try {
        this.$router.push('/');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>

</style>
