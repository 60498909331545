import gql from 'graphql-tag';

export default gql`
mutation updateRankByPk($pageId: Int!, $orderIndex: Int!) {
  update_pages_by_pk(
    pk_columns: { page_id: $pageId }, 
    _set: { order_index: $orderIndex }) {
    page_id
  }
}
`;
