const dashboardItemTourMixin = {
  methods: {
    dashboardItemTour() {
      // show the tour only if the dashboard tour is already finished
      if (
        this.$localStorage.tourOfDashboardFinished
        && !this.$localStorage.tourOfDashboardItemFinished
      ) {
        this.$nextTick(() => {
          const dashboardItemTour = this.$shepherd({
            useModalOverlay: true,
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              classes: 'vue-tour',
              scrollTo: true,
              popperOptions: {
                modifiers: [{
                  name: 'offset',
                  options: { offset: [0, 10] },
                }],
              },
            },
            scrollTo: { behavior: 'smooth', block: 'center' },
          });
          dashboardItemTour.on('cancel', this.setTheLocalStorageForDashboardItemTour);
          dashboardItemTour.on('complete', this.setTheLocalStorageForDashboardItemTour);
          dashboardItemTour.addStep({
            id: 'dashboard-item-step-1',
            title: this.$t('tour.dashboardItem.step1.title'),
            text: this.$t('tour.dashboardItem.step1.text'),
            attachTo: {
              element: '.item-card.project, .item-card.page',
              on: 'right',
            },
            buttons: [
              {
                text: this.$t('tour.skip'),
                classes: 'light-grey-button prev',
                action: dashboardItemTour.cancel,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: dashboardItemTour.next,
              },
            ],
          });
          dashboardItemTour.addStep({
            id: 'dashboard-item-step-2',
            title: this.$t('tour.dashboardItem.step2.title'),
            text: this.$t('tour.dashboardItem.step2.text'),
            attachTo: {
              element: '.item-card.project .context-menu, .item-card.page .context-menu',
              on: 'bottom',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: dashboardItemTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: dashboardItemTour.next,
              },
            ],
          });
          dashboardItemTour.addStep({
            id: 'dashboard-item-step-3',
            title: this.$t('tour.dashboardItem.step3.title'),
            text: this.$t('tour.dashboardItem.step3.text'),
            attachTo: {
              element: '.table-search',
              on: 'bottom',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: dashboardItemTour.back,
              },
              {
                text: this.$t('tour.finish'),
                classes: 'green-button',
                action: dashboardItemTour.complete,
              },
            ],
          });
          dashboardItemTour.start();
        });
      }
    },
    setTheLocalStorageForDashboardItemTour() {
      this.$localStorage.tourOfDashboardItemFinished = true;
    },
  },
};

export default dashboardItemTourMixin;
