<template>
  <div>
    <b-modal
      id="modal-component-page"
      size="lg"
      header-class="d-block position-relative"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createItem.page.choosePageTemplate') }}</h3>
        <b-button
          v-if="parentItem.type !== 'project'"
          class="position-absolute top-0"
          @click="goBackInModal"
        >
          <b-icon icon="arrow-left-circle-fill"></b-icon>
        </b-button>
      </template>
      <template #default>
        <b-row>
          <b-col cols="12" sm="3" class="border-right-light-grey">
            <ul class="page_templates pl-2 mt-5 pb-5">
              <li class="nav-item">
                <a
                  href="#"
                  :class="{ active: (selectedSharedItem === null) }"
                  class="nav-link"
                  @click="loadEmptyContent()"
                >
                  {{ $t('modals.createItem.page.empty') }}
                </a>
              </li>
              <li v-for="(item, key) in sharedPages" class="nav-item" :key="`${item.type}-${key}`">
                <a
                  v-if="item.type === 'page'"
                  href="#"
                  :class="{ active: (item.isActive) }"
                  class="nav-link"
                  @click="loadPreviewContent(key)">
                  {{ item.name }}
                </a>
                <div
                  v-else
                  class="d-flex flyout py-2"
                  @click="toggleFlyout(key)"
                >
                  <span class="pl-3 cursor-pointer">{{ item.name }}</span>
                  <b-icon
                    v-if="item.showChildren"
                    icon="chevron-down"
                    aria-hidden="true"
                    class="icon"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="chevron-right"
                    aria-hidden="true"
                    class="icon"
                  ></b-icon>
                </div>
                <ul
                  v-if="item.showChildren && item.children && item.children.length"
                  class="pl-3">
                  <li
                    class="pb-2"
                    v-for="(childPage, index) in item.children"
                    :key="index"
                  >
                    <a
                      v-if="childPage.type === 'page'"
                      href="#"
                      :class="{ active: (childPage.isActive) }"
                      class="py-0 nav-link"
                      @click="loadChildPreviewContent(key, index)">
                      {{ childPage.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" sm="9">
            <div v-if="selectedSharedItem === null" class="m-5 pb-5">
              <h4>{{ $t('modals.createItem.page.emptyPage') }}</h4>
              <p>{{ $t('modals.createItem.page.emptyPageDesc') }}</p>
              <b-button
                class="mr-0 ml-0 green-button"
                @click="showPageNameModal"
              >
                <b-icon
                  icon="plus-circle-fill"
                  class="mr-2"
                  aria-hidden="true"
                  scale="1"
                ></b-icon>
                {{ $t('webmag.select') }}
              </b-button>
            </div>
            <modal-content-show-item-preview
              v-else-if="groupId"
              :group-id="groupId"
              :group-slug="groupSlug"
              :group-domain="groupDomain"
              :group-path="groupPath"
              :parent-item="parentItem"
              :parent-slug-path="parentSlugPath"
              :shared-item="selectedSharedItem"
              :key="previewKey"
              @open-name-modal="showPageNameModal"
            />
          </b-col>
        </b-row>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          @click="close()"
          block
          class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-component-page-name"
      ref="modal-component-page-name"
      footer-class="flex-nowrap"
      centered
      no-stacking
    >
      <template #modal-header>
        <h3>{{ $t('modals.createItem.page.insertPageName') }}</h3>
      </template>

      <template #default>
        <modal-choose-name-content
          :group-id="groupId"
          :item-name.sync="itemName"
          :item-slug.sync="itemSlug"
          :group-slug="groupSlug"
          :child-slugs="childSlugs"
          item-type="page"
          @enter-pressed="confirmOkModalByEnterKey"
        ></modal-choose-name-content>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!isNameSet"
          variant="primary"
          class="p-3"
          block
          @click="handleOk"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DuplicateSharedItem from '@/graphQlQueries/mutations/duplicateSharedItem';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import UpdatePageAfterDuplicate from '@/graphQlQueries/mutations/updatePageAfterDuplicate';
import GetFolderSettingsById from '@/graphQlQueries/queries/getFolderSettingsById';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetLocaleIdOfProject from '@/graphQlQueries/queries/getLocaleIdOfProject';
import GetSharedTemplates from '@/graphQlQueries/queries/getSharedTemplates';
import GetTemplateCollectionIdByProjectId from '@/graphQlQueries/queries/getTemplateCollectionIdByProjectId';
import InsertNewPage from '@/graphQlQueries/mutations/insertNewLinkItem';
import DeletePageTagsByPageId from '@/graphQlQueries/mutations/deletePageTagsByPageId';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import UserDataAndAccess from '@/mixins/userDataAndAccess';

export default {
  name: 'ModalComponentPage',
  components: {
    ModalContentShowItemPreview: () => import('@/components/modals/modalContent/ModalContentShowItemPreview.vue'),
    ModalChooseNameContent: () => import('@/components/modals/ModalChooseNameContent.vue'),
  },
  mixins: [SendPublishedDataToServer, UserDataAndAccess],
  props: {
    itemPosition: {
      type: Number,
      default: null,
    },
    childSlugs: {
      type: Array,
      required: true,
    },
    parentItem: {
      type: Object,
      required: true,
    },
    groupSlug: {
      type: String,
      required: true,
    },
    groupDomain: {
      type: String,
      required: true,
    },
    groupPath: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    sharedPages: null,
    selectedSharedItem: null,
    parentSlugPath: null,
    previewKey: 1,
    itemName: null,
    itemSlug: null,
    templateCollectionIdValue: null,
    templateIdValue: null,
    templateCollectionIdOfProject: null,
    runTemplateCollectionIdOfProject: false,
    languageIdOfParent: null,
  }),
  created() {
    console.log('this', this);
    this.parentSlugPath = (this.$route.params.parametersOfUrl)
      ? `/${this.$route.params.parametersOfUrl}`
      : null;
    if (this.parentItem.type === 'project') {
      this.runTemplateCollectionIdOfProject = true;
    }
    // hardcode the templateID depending if page is created in folder or project
    // TODO: right now we only have on template stored in the database
    this.templateIdValue = 1;
    if (this.parentItem.type === 'folder') {
      this.templateCollectionIdValue = 1;
    }
  },
  apollo: {
    languageIdOfParent: {
      query() {
        if (this.parentItem.type === 'folder') {
          return GetFolderSettingsById;
        }
        return GetLocaleIdOfProject;
      },
      variables() {
        return (this.parentItem.type === 'folder')
          ? { id: this.parentItem.id }
          : { projectId: this.parentItem.id };
      },
      update(data) {
        if (this.parentItem.type === 'folder') {
          if (data.folders[0]?.language_id) {
            return data.folders[0].language_id;
          }
        }
        if (this.parentItem.type === 'project') {
          if (data.projects[0]?.language_id !== null) {
            return data.projects[0].language_id;
          }
        }
        return null;
      },
      fetchPolicy: 'network-only',
    },
    templateCollectionIdOfProject: {
      query: GetTemplateCollectionIdByProjectId,
      variables() {
        return {
          projectId: this.parentItem.id,
        };
      },
      update(data) {
        this.runTemplateCollectionIdOfProject = false;
        if (data.projects.length) {
          this.templateCollectionIdValue = data.projects[0].template_collection_id;
          return data;
        }
        return null;
      },
      skip() {
        return this.parentItem.type !== 'project';
      },
    },
    groupPath: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          return data.groups[0].group_path;
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
    },
    sharedPages: {
      query: GetSharedTemplates,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        console.log('data1', data);
        const taggedPagesTemplates = [];
        if (data.getSharedTemplates && data.getSharedTemplates.length) {
          data.getSharedTemplates.forEach((sharedProject) => {
            const parentSlugPath = (sharedProject.slug_path)
              ? sharedProject.slug_path.substring(
                0, sharedProject.slug_path.lastIndexOf('/'),
              )
              : null;

            const previewActionObject = {
              slug: sharedProject.slug,
              groupSlug: sharedProject.group_slug,
              parentSlugPath,
            };
            const iframeSourceForPreview = `${sharedProject.domain}${sharedProject.slug_path}/`;

            // eslint-disable-next-line no-param-reassign
            sharedProject = {
              iframeSourceForPreview,
              previewActionObject,
              itemName: sharedProject.name,
              ...sharedProject,
            };
            if (sharedProject.type === 'page') {
              if (sharedProject.projects_tags && sharedProject.projects_tags.length) {
                // prepare the tagged object
                sharedProject.projects_tags.forEach((tagsObject) => {
                  const tagId = tagsObject.tag.tag_id;
                  const existingItem = taggedPagesTemplates.find((item) => tagId === item.tagId);
                  if (!existingItem) {
                    taggedPagesTemplates.push({
                      name: tagsObject.tag.name,
                      tagId,
                      type: 'tag',
                      showChildren: false,
                      children: [{
                        ...sharedProject,
                        type: 'page',
                        isActive: false,
                      }],
                    });
                  } else {
                    existingItem.children.push({
                      ...sharedProject,
                      type: 'page',
                      isActive: false,
                    });
                  }
                });
              } else {
                taggedPagesTemplates.push({
                  ...sharedProject,
                  type: 'page',
                  isActive: false,
                });
              }
            }
          });
        }
        // sort after name
        taggedPagesTemplates.sort(
          // eslint-disable-next-line no-nested-ternary,max-len
          (a, b) => (a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0),
        );
        // sort the children too
        taggedPagesTemplates.forEach((item) => {
          if (item.type === 'tag' && item.children.length > 1) {
            item.children.sort(
              // eslint-disable-next-line no-nested-ternary,max-len
              (a, b) => (a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0),
            );
          }
        });
        console.log('taggedPagesTemplates', taggedPagesTemplates);
        return taggedPagesTemplates;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.groupId;
      },
    },
  },
  computed: {
    isNameSet() {
      return !!this.itemName;
    },
  },
  methods: {
    goBackInModal() {
      this.$root.$emit('bv::hide::modal', 'modal-component-page');
      this.$root.$emit('bv::show::modal', 'modal-item-type');
    },
    showPageNameModal() {
      this.itemName = (this.selectedSharedItem) ? this.selectedSharedItem.itemName : null;
      this.$root.$emit('bv::hide::modal', 'modal-component-page');
      this.$root.$emit('bv::show::modal', 'modal-component-page-name');
    },
    toggleFlyout(tagIndex) {
      this.sharedPages[tagIndex].showChildren = !this.sharedPages[tagIndex].showChildren;
    },
    loadEmptyContent() {
      this.removeAllActiveStateOfTabs();
      this.selectedSharedItem = null;
      this.previewKey++;
    },
    loadPreviewContent(index) {
      this.removeAllActiveStateOfTabs();
      this.sharedPages[index].isActive = true;
      this.selectedSharedItem = this.sharedPages[index];
      this.previewKey++;
    },
    loadChildPreviewContent(tagIndex, childIndex) {
      this.removeAllActiveStateOfTabs();
      this.sharedPages[tagIndex].children[childIndex].isActive = true;
      this.selectedSharedItem = this.sharedPages[tagIndex].children[childIndex];
      this.previewKey++;
    },
    removeAllActiveStateOfTabs() {
      // remove all active keys
      this.sharedPages.forEach((sharedPage, key) => {
        if (sharedPage.isActive) {
          this.sharedPages[key].isActive = false;
        }
        if (sharedPage.type === 'tag' && sharedPage.children.length) {
          sharedPage.children.forEach((childPage, childKey) => {
            this.sharedPages[key].children[childKey].isActive = false;
          });
        }
      });
    },
    focusOnNextButton() {
      this.$nextTick(() => {
        const button = document.getElementById('next-button');
        button.focus();
      });
    },
    confirmOkModalByEnterKey() {
      this.handleOk();
    },
    async handleOk() {
      if (this.selectedSharedItem) {
        await this.duplicatePage();
      } else {
        await this.createNewPage();
      }
    },
    async duplicatePage() {
      // run the loader
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const dataToBeSendToTheServer = {
        slug: this.itemSlug,
        parent_slug_path: this.parentSlugPath || '/',
        group_slug: this.groupSlug,
        group_domain: this.groupDomain,
        type: 'page',
      };
      const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
        action: 'duplicateItem',
        data: dataToBeSendToTheServer,
      });
      try {
        if (publishedDataWasSuccessfulSend) {
          const duplicateObject = {
            name: this.itemName,
            slug: this.itemSlug,
            destination_folder_id: (this.parentItem.type === 'folder') ? this.parentItem.id : null,
            destination_project_id: (this.parentItem.type === 'project') ? this.parentItem.id : null,
            page_id: this.selectedSharedItem.page_id,
            group_id: this.groupId,
            project_id: null,
          };

          const { data: { duplicateSharedTemplate } } = await this.$apollo.mutate({
            mutation: DuplicateSharedItem,
            variables: {
              object: duplicateObject,
            },
          });
          await this.cleanupDuplicatedPage(duplicateSharedTemplate);
          // create a new gist entry for page create
          if (window.gist) {
            window.gist.identify(this.email, { email: this.email, tags: 'pages_trigger' });
          }
          this.templateIdValue = null;
          this.$refs['modal-component-page-name'].hide();
          const path = (this.$route.params.parametersOfUrl)
            ? `/${this.$route.params.parametersOfUrl}/${this.itemSlug}`
            : `/${this.itemSlug}`;
          await this.$router.push({
            path,
            query: { page: 'new' },
          });
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    async cleanupDuplicatedPage(duplicateItem) {
      console.log('duplicateItem', duplicateItem);
      await this.$apollo.mutate({
        mutation: UpdatePageAfterDuplicate,
        variables: {
          pageId: duplicateItem.page_id,
          isSharedTemplate: false,
          orderIndex: this.itemPosition,
          published: false,
        },
      });
      // remove the tags from the duplicated page
      await this.$apollo.mutate({
        mutation: DeletePageTagsByPageId,
        variables: {
          pageId: duplicateItem.page_id,
        },
      });
      return 'cleanup the duplicated page';
    },
    async createNewPage() {
      try {
        const parentFolderId = (this.parentItem.type === 'folder') ? this.parentItem.id : null;
        const parentProjectId = (this.parentItem.type === 'project') ? this.parentItem.id : null;
        const themeId = (this.parentItem.type === 'folder') ? 1 : null;
        const defaultPreviewPage = {
          image: {
            url: 'https://axyqwmwryo.cloudimg.io/v7/https://cdn.webmag.io/webmag/snippets/default/thumb_default.jpg',
            width: null,
            height: null,
            focalpoint: {
              x: 50,
              y: 50,
            },
          },
        };
        const obj = {
          parent_folder_id: parentFolderId,
          parent_project_id: parentProjectId,
          slug: this.itemSlug,
          order_index: this.itemPosition,
          template_collection_id: this.templateCollectionIdValue,
          template_id: this.templateIdValue,
          name: this.itemName,
          language_id: this.languageIdOfParent,
          theme_id: themeId,
          thumbnails: defaultPreviewPage,
        };
        await this.$apollo.mutate({
          mutation: InsertNewPage,
          variables: {
            obj,
          },
        });
        // create a new gist entry for page create
        if (window.gist) {
          window.gist.identify(this.email, { email: this.email, tags: 'pages_trigger' });
        }
        this.templateIdValue = null;
        this.$refs['modal-component-page-name'].hide();
        const path = (this.$route.params.parametersOfUrl)
          ? `/${this.$route.params.parametersOfUrl}/${this.itemSlug}`
          : `/${this.itemSlug}`;
        await this.$router.push({
          path,
          query: { page: 'new' },
        });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>

<style lang="scss">
.flyout {
  justify-content: space-between;

  &>span{
    cursor:pointer;
    color: #777C7F;
  }
}

ul {
  list-style: none;
  font-size: 15px;
}

li a {
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;

  &.active {
    color: #53dc86;
  }
}
.icon {
  position: relative;
  top: 4px;
}
</style>
