var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "incompatibleItem",
      attrs: {
        id: "incompatible-item",
        "no-close-on-backdrop": _vm.modalForDetailPage,
        centered: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("modals.incompatibleItem.title")))
              ])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function({ close }) {
            return [
              _vm.modalForDetailPage
                ? _c(
                    "b-button",
                    {
                      staticClass: "p-3 border-right-light-grey",
                      attrs: { block: "" },
                      on: { click: _vm.redirectToDashboard }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.toDashboard")) + " ")]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "p-3 border-right-light-grey",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                  )
            ]
          }
        }
      ])
    },
    [
      _c("div", { staticClass: "p-5" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("h3", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.item.name))]),
          _c("h4", { staticClass: "mb-4" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("modals.incompatibleItem.desc")))
            ])
          ]),
          _vm.item.published
            ? _c("div", [
                _c(
                  "a",
                  { attrs: { href: _vm.publishingUrl, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.publishingUrl))]
                )
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }